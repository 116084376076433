import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component'
import { RegisterComponent } from './pages/register/register.component'

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: 'Interactive Demo Login',
  },
  {
    path: 'register',
    component: RegisterComponent,
    title: 'Interactive Demo Registration'
  },
  {
    path: '**',
    component: LoginComponent,
    title: 'Interactive Demo Login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
