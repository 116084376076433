<h3 mat-dialog-title class="model-title">Registration</h3>
<div mat-dialog-content class="modal-content">
	<ng-container *ngIf="demoNameReg === 'apisec' || demoNameReg === 'cpro'; else noApiSec">
		<div class="row">
			<div class="col-12">
				<strong><p>Success! Username and password created.</p></strong>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-2">
			</div>
			<div class="col-sm-10">
				<p class="text-start">Username: {{ userName }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-2">			
			</div>
			<div class="col-sm-10">
				<p class="text-start">Password: {{ userPassword }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p><strong>{{ registerAfterMessage }}</strong></p>
			</div>
		</div>
		<div class="row" *ngIf="shouldShowLoginLink()">
			<div class="col-12" align="center">
				<p>You can access the demo via the <a target="_blank" [href]="getLoginPageUrl()">Login Page</a></p>
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-12">
				<p><strong>WARNING: </strong>The generated invitation link will expire in the next 24 hours.<br>After that period, you won't be able to activate your account with the <br>provided link.</p>
			</div>
		</div> -->
	</ng-container>
	
	<ng-template #noApiSec>
		<div class="row">
			<div class="col-12">
				<strong><p>Success! Username and password created.</p></strong>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-2">
			</div>
			<div class="col-sm-10">
				<p class="text-start">Username: {{ userName }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-2">			
			</div>
			<div class="col-sm-10">
				<p class="text-start">Password: {{ userPassword }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<p>Copied to clipboard. Please send these to your customer.</p>
			</div>
		</div>
		<div class="row" *ngIf="shouldShowLoginLink()">
			<div class="col-12" align="center">
				<p>Customer can access the demo via the <a target="_blank" [href]="getLoginPageUrl()">Login Page</a></p>
			</div>
		</div>
	</ng-template>
	<div mat-dialog-actions align="center">
	<button mat-flat-button mat-dialog-close class="button-color-akamai">Close</button>
</div>

