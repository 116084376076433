<div class="row g-0 text-center">
    <div class="col-sm-6 col-md-8">
        <mat-toolbar class="toolbar-style">
            <div class="row">
                <div class="col-md-12 col-md-8 text-center">
                    <img class="akamai-logo-style" src="../../../assets/akamai-logo.svg">
                </div>
            </div>
        </mat-toolbar>
    </div>
    <div class="col-6 col-md-4 toolbar-style-login">
        <!-- <button type="submit" class="button-color-akamai-login" (click)="registerDemo()">Register</button> -->
    </div>
</div>
<div class="row demo-header">
    <div class="container">
        <div class="col-md-6 demo-title">
            <div class="row">
                <div class="col-md-12">
                    <h2>{{ demoName_h2 }}</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h3>{{ demoName_h3 }}</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p>{{ demoName_p }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="show_video" class="col-md-6 demo-video">
            <div class="row">
                <div class="col-md-12">
                    <video controls="controls" style="margin-left: 150px;" width="420" height="200" name="Video Name" src="../../../assets/{{ video_name }}"></video>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-12">
                    <h3>Header 3</h3>
                </div>
            </div>             -->
        </div>
    </div>
</div>
<div *ngIf="errorFound" class="alert alert-warning alert-dismissible fade show" role="alert">
    {{ errorMessage }}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="closeButton()"></button>
</div>
<!-- <div *ngIf="successFound" class="alert alert-success alert-dismissible fade show" role="alert">
    {{ successMessage }}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click)="closeButton()"></button>
</div> -->
<div class="container background-color-login">
    <div class="demo-body">
        <div class="row">
            <div *ngIf="!displayUserCreds; else showUserCredsHeader" class="col-md-6 login-text">
                <div *ngIf="demoNameReg === 'apisec'; else noApiSec">
                    <p>Please login to get your Akamai API Security invitation link.</p>
                </div>
                <ng-template #noApiSec>
                    <p>Your Control Center Credentials are {{demoName}}:</p>
                </ng-template>
                
            </div>
            <ng-template #showUserCredsHeader>
                <div class="col-md-6 login-text">
                    <div *ngIf="demoNameReg === 'apisec'; else noApiSec">
                        <p>Your Akamai API Security invitation link is:</p>
                    </div>
                    <ng-template #noApiSec>
                        <p>Your Control Center Credentials are {{demoName}}:</p>
                    </ng-template>
                </div>
            </ng-template>
            <div *ngIf="!displayUserCreds; else showUserCredsText" class="col-md-6 form-text">
                <form class="example-form" [formGroup]="demoForm" (ngSubmit)="onSubmit()" #myForm="ngForm">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">Username:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput formControlName="userName">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-label class="form-label-style">Password:</mat-label>
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input matInput value="" type="password" formControlName="userPwd">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div *ngIf="show_tc_checkbox" class="row">
                                <div class="col-md-12">
                                    <section class="example-full-width">
                                        <mat-checkbox class="example-margin" [(ngModel)]="checked" [ngModelOptions]="{standalone: true}">I Agree to Akamai's <a href="https://www.akamai.com/legal/portal-terms" target=”_blank”>Portal Terms of Use</a></mat-checkbox>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">                            
                            <section *ngIf="!createInProgress" class="example-section">
                                <button type="submit" [disabled]="demoForm.invalid || !checked" class="button-color-akamai" mat-flat-button>Login</button>
                            </section>
                            <section class="example-section" *ngIf="createInProgress">
                                <button type="submit" [disabled]="true" class="button-color-akamai" mat-flat-button><mat-spinner diameter="25"></mat-spinner></button>
                            </section>
                        </div>
                    </div>
                </form>                  
            </div>
            <ng-template #showUserCredsText>
                <div class="col-md-6 form-text">
                    <div class="row">
                        <mat-card class="example-card">
                            <mat-card-header>
                                <p><strong>Demo credentials expire on: {{ expireAccountDateStr }}</strong></p>
                            </mat-card-header>
                            <mat-card-content class="login-content">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Username: </p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{ userName }}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Password:</p>
                                    </div>
                                    <div class="col-md-8">
                                        <p>{{ userPassword }}</p>
                                    </div>
                                </div>
                            </mat-card-content>
                            <mat-card-actions class="login-frame">
                                <section class="example-section">
                                    <button type="submit" class="button-color-akamai-acc" mat-flat-button (click)="openAcc()">Enter Akamai Control Center</button>
                                </section>
                            </mat-card-actions>
                        </mat-card>
                    </div>
                </div>                
            </ng-template>
        </div>
    </div>    
</div>

