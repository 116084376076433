//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//	Build by: mruiz@akamai.com
//	v1.0
//	Jul 07, 2023 
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Postloginuser } from '../../interface/postuserlogin'
import { ApicallsService } from '../../services/apicalls.service'

@Component({
	selector: 'app-login',
  	templateUrl: './login.component.html',
  	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
	constructor(private _apicallsService: ApicallsService) {}
	show_tc_checkbox = false
	show_video: boolean = true
	errorFound: boolean = false
	errorMessage: string = ""
	displayUserCreds: boolean = false
	// successMessage: string = ""

	demoName: string = "";
	demoForm = new FormGroup({
		userName: new FormControl('', [Validators.required]),
		userPwd: new FormControl('', [Validators.required]),
	});

	createInProgress: boolean = false
	checked: boolean = false;
	demoNameReg: string = ""

	urlParamsString: string = ""
	demoName_h2: string = ""
	demoName_h3: string = ""
	demoName_p: string = ""
	video_name: string = ""

	postLoginUser: Postloginuser = {
		'username' : "",
		'password' : "",
	}
	
	userName: string = "<failed_to_get_username>"
	userPassword: string = "<failed_to_get_password>"
	expireAccountDate: string = ""
	expireAccountDateStr: string = "";

	ngOnInit(): void {

		const currentUrl = window.location.search;
		// const urlParams = new URLSearchParams(currentUrl);
		const urlParams = new URLSearchParams(currentUrl);
		const demoName = urlParams.get('demo')
		this.urlParamsString = demoName as string
		if(demoName == "gdc"){
			this.demoName_h2 = "Guardicore";
			this.demoNameReg = demoName
			this.show_video = false
			this.show_tc_checkbox = true
		}
		if(demoName == "asm"){
			this.demoName_h2 = "App & API Protector Demo";
			this.demoName_h3 = "with Advanced Security Management";
			this.demoName_p = "If you're new to App & API Protector, start with this video:";
			this.video_name = "aap_asm_demo.mp4"
			this.demoNameReg = demoName
			this.checked = true

		}
		if(demoName == "aap"){
			this.demoName = "APP & API";
			this.demoName_h2 = "App & API Protector Demo"
			this.demoName_p = "If you're new to App & API Protector, start with this video:";
			this.video_name = "aap_demo.mp4"
			this.demoNameReg = demoName
			this.checked = true
		}
		if(demoName == "apisec"){
			this.demoNameReg = demoName
			this.demoName = "API Security";
			this.demoName_h2 = "API Security Demo"
			this.show_video = false
			this.checked = true
		}
		if(demoName == "cpro"){
			this.demoNameReg = demoName
			this.demoName = "Content Protector Demo"
			this.demoName_h2 = "Content Protector Demo"
			this.show_video = false
			this.checked = true
		}
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//When SUBMIT button is clicked
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	onSubmit(){
		console.log("this.demoName::"+this.demoName)
		console.log("this.demoNameReg::"+this.demoNameReg)
		if(this.demoForm.valid) {
			this.createInProgress = true
			this.postLoginUser = {
				'username' : this.demoForm.controls['userName'].value as string,
				'password' : this.demoForm.controls['userPwd'].value as string
			}
			console.log('Form is VALID...Sending request...');
			console.log(JSON.stringify(this.postLoginUser));
			this.checkUserLogin(this.postLoginUser);
		}
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//When check if user has a valid entry in demo database
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	checkUserLogin(postLoginUser: Postloginuser){

		this._apicallsService.checkUserLogin(postLoginUser).subscribe({
			next: data => {
				console.log(data)
				if((data.response['user_status'] != 'ok_login_user') || (data.status_code != 200)){
					this.createInProgress = false
					this.errorFound = true
					if(data.response['user_status']){
						this.errorMessage = data.response['user_status']
					}
					else{
						const testAkamaiEmail: RegExp = /connection to server at/;
						this.errorMessage = 'Can\'t connect to database! Please contact SE Central Support Team.'
					}
					
				}
				else{
					this.errorFound = false;
					this.createInProgress = false;
					this.displayUserCreds = true;
					this.userName = data.response['user_data'][11];
					this.userPassword = data.response['user_data'][14];
					this.expireAccountDate = data.response['user_data'][9];	//2023-07-02T00:00:00+00:00
					const [dateComponents, timeComponents] = this.expireAccountDate.split('T');
					const [year, month, day] = dateComponents.split('-');
					const [timeComponentsJustSecsA, timeComponentsJustSecsB] = timeComponents.split('+');					
					const [hours, minutes, seconds] = timeComponentsJustSecsA.split(':');					
					const date = new Date(+year, +month + 2, +day, +hours, +minutes, +seconds);
					this.expireAccountDateStr = date.toLocaleString()			
				}
			},
			error: err => {
				this.errorMessage =  err	
			},
			complete: () => {				
				if(this.displayUserCreds){
					this.createInProgress = false
					// this.clipboard.copy("username: "+this.demoForm.controls['userName'].value+"\npassword: "+this.demoForm.controls['userPwd'].value)
				}
			},
		});
	}

	openAcc(){
		window.open("https://control.akamai.com/apps/auth/#/login");
	}
	// openApiSec(){
	// 	window.open("https://control.akamai.com/apps/auth/#/login");
	// }

	registerDemo(){
		window.open("/register?demo="+this.demoNameReg, "_self");
	}
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	//When CLOSE button is clicked
	//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
	closeButton(){
		// this.successFound = false
		this.errorFound = false;
	}
}
