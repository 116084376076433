import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Postcreateuser } from '../interface/postcreateuser'
import { Postloginuser } from '../interface/postuserlogin'
import { PostEmailCreateUser } from '../interface/postemailcreateuser'
import { Apiresponse } from '../interface/apiresponse'
import { environment } from 'src/environments/environment';


@Injectable({
	providedIn: 'root'
})

export class ApicallsService {

	constructor(private http:HttpClient) { }

	httpOptions = {
		headers: new HttpHeaders(
			{ 
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				'Access-Control-Allow-Methods' : 'GET,PUT,OPTIONS,POST',
				'Access-Control-Request-Headers' : 'access-control-allow-methods,access-control-allow-origin,content-type'
			})
	};

	apiBackendEndpoint = environment.apiBackendUrl

	createDemoUser(postCreateUser: Postcreateuser) {
		return this.http.post<Apiresponse>(this.apiBackendEndpoint+'/api/v1/demos/set/user', JSON.stringify(postCreateUser), this.httpOptions);
	}
	checkUserLogin(postLoginUser: Postloginuser) {
		return this.http.post<Apiresponse>(this.apiBackendEndpoint+'/api/v1/demos/get/login', JSON.stringify(postLoginUser), this.httpOptions);
	}
	sendCreateUserEmail(postEmailCreateUser: PostEmailCreateUser){
		return this.http.post<Apiresponse>('https://email-sender-api-staging.a.hh.appbattery.akadns.net/api/v1/send/email', JSON.stringify(postEmailCreateUser), this.httpOptions);
	}
}


